<template>
  <div id="search-results" class="fill">
    <HeaderNavigation title="SELECTIONNER UN POINT DE RECHARGE" />
    <b-container class="main-container">
      <b-row>
        <b-col>
          <b-form novalidate>
            <b-form-row>
              <b-col cols="10" md="9">
                <b-form-input
                  type="text"
                  class="address"
                  readonly
                  v-model="search.destination.address"
                />
              </b-col>
              <b-col cols="4" md="3" class="text-right">
                <span class="search-actions">
                  <fa-icon icon="redo-alt" @click="doSearch()" />
                  <fa-icon icon="sliders-h" @click="$router.back()" />
                </span>
                <b-button v-if="false" class="filter" variant="light" disabled
                  >FILTRER</b-button
                >
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col>
                <b-form-group>
                  <label slot="label">
                    Quel temps de recharge
                    <b>{{ formattedDuration }}</b>
                  </label>
                  <b-form-slider
                    :value="[1, 3]"
                    :max="4"
                    handle="square"
                    tooltip="always"
                    :tooltip-split="true"
                    :formatter="formatTime"
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
    <div class="azure-map-container" :class="{ searching }">
      <AzureMap
        :center="position"
        :zoom="15"
        class="azure-map"
        @ready="onMapReady"
        @boxzoomend="doSearch()"
        @dragend="doSearch()"
        @zoomstart="onZoomStart"
        @zoomend="onZoomEnd"
      >
        <!-- Azure Map controls -->
        <AzureMapZoomControl />

        <!-- Search Location Marker -->
        <AzureMapDataSource>
          <AzureMapPoint :longitude="position[0]" :latitude="position[1]" />
          <AzureMapBubbleLayer
            :options="userLayerOptions.pulse"
            @created="onPulseLayerCreated"
          />
          <AzureMapBubbleLayer :options="userLayerOptions.dot" />
        </AzureMapDataSource>

        <!-- Create a Data Source -->
        <AzureMapDataSource
          ref="dataSource"
          :cluster="true"
          :cluster-properties="clusterProperties"
        >
          <!-- Add Points to the Data Source -->
          <AzureMapPoint
            v-for="point in points"
            :key="point.properties.id"
            :longitude="point.longitude"
            :latitude="point.latitude"
            :properties="point.properties"
          />

          <!-- Add popup to show CP details -->
          <AzureMapPopup
            v-model="popup.open"
            :position="popup.coords"
            :pixel-offset="[0, -43]"
            class="popup"
            :class="{
              available: cp && cp.isAvailableDuring,
              'part-available': cp && (cp.endsTooEarly || cp.startsTooLate),
              unavailable: cp && cp.isNotAvailableAtAll
            }"
            :fillColor="popupFillColor"
          >
            <ChargingPointPopup v-if="!!cp" :cp="cp" />
          </AzureMapPopup>

          <!-- Add a Symbol Layer to render the Points stored in the Data Source -->
          <AzureMapSymbolLayer
            :options="symbolLayerOptions"
            @click="onMapClick"
          />

          <!-- Add layers to render CP clusters -->
          <AzureMapBubbleLayer
            :options="bubbleLayerOptions"
            @click="onClusterClick"
          />
          <AzureMapSymbolLayer :options="textLayerOptions" />
        </AzureMapDataSource>
      </AzureMap>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import SearchMixin from '@/mixins/search.js'
import HeaderNavigation from '@/components/HeaderNavigation.vue'
import * as atlas from 'azure-maps-control'
import {
  AzureMap,
  AzureMapDataSource,
  AzureMapPoint,
  AzureMapZoomControl,
  AzureMapBubbleLayer,
  AzureMapSymbolLayer,
  AzureMapPopup
} from 'vue-azure-maps'
import ChargingPointPopup from '@/components/Search/ChargingPointPopup.vue'
//import { instance } from 'vue-stripe-elements-plus' // TODO: See how we can use instance import
import store from '../store' // TODO: See if it can be done properly
import ApiService from '@/services/api-services'
import { SearchResultCP } from '../utils/search-utils'
import { Routes } from '@/router.js'

const moment = require('moment')
moment.locale('fr-FR')

export default {
  name: 'search-results',
  components: {
    HeaderNavigation,
    AzureMap,
    AzureMapDataSource,
    AzureMapPoint,
    AzureMapZoomControl,
    AzureMapBubbleLayer,
    AzureMapSymbolLayer,
    AzureMapPopup,
    ChargingPointPopup
  },
  mixins: [SearchMixin],
  data() {
    return {
      cp: undefined,
      userLayerOptions: {
        pulse: {
          color: 'rgb(, 204, 255)',
          strokeWidth: 0,
          radius: 0,
          opacity: 0.4
        },
        dot: {
          color: 'orange',
          radius: 4,
          strokeWidth: 2
        }
      },
      clusterProperties: {
        available: [
          '+',
          [
            'match',
            ['get', 'availabilityStatus'],
            ['Public-Available', 'Private-Available'],
            1,
            0
          ]
        ],
        partAvailable: [
          '+',
          [
            'match',
            ['get', 'availabilityStatus'],
            ['Public-PartAvailable', 'Private-PartAvailable'],
            1,
            0
          ]
        ],
        unavailable: [
          '+',
          [
            'match',
            ['get', 'availabilityStatus'],
            ['Public-Unavailable', 'Private-Unavailable'],
            1,
            0
          ]
        ]
      },
      bubbleLayerOptions: {
        radius: 15,
        color: '#152536',
        filter: ['has', 'point_count']
      },
      textLayerOptions: {
        iconOptions: {
          image: 'none'
        },
        textOptions: {
          textField: ['get', 'point_count_abbreviated'],
          offset: [0, 0.4],
          color: '#ffffff',
          size: 14
        }
      },
      symbolLayerOptions: {
        iconOptions: {
          ignorePlacement: true,
          allowOverlap: true,
          image: [
            'match',

            ['get', 'availabilityStatus'],

            //For each entity type, specify the icon name to use.
            'Public-Available',
            'public-available',
            'Public-PartAvailable',
            'public-part-available',
            'Public-Unavailable',
            'public-unavailable',
            'Private-Available',
            'private-available',
            'Private-PartAvailable',
            'private-part-available',
            'Private-Unavailable',
            'private-unavailable',

            //Default fallback icon.
            'marker-blue'
          ]
        },
        filter: ['!', ['has', 'point_count']]
      },
      map: null,
      pulseLayer: null,
      zoomStart: null,
      selectedShape: null,
      popup: {
        open: false,
        coords: [0, 0]
      },
      points: [],
      searching: false
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo
    },
    position() {
      return (pos => (pos ? [pos.lon, pos.lat] : [0, 0]))(
        this.search.destination.position
      )
    },
    formattedDuration() {
      return this.formatDuration(this.search.duration)
    },
    popupFillColor() {
      return this.cp
        ? this.cp.isNotAvailableAtAll
          ? '#E0D8BC' // Unavailable
          : this.cp.endsTooEarly || this.cp.startsTooLate
          ? '#FF6302' // Part-Available
          : '#19E6D0' // Available
        : undefined
    }
  },
  beforeRouteEnter(to, from, next) {
    if (from.name == Routes.SEARCH.name && store.state.search.destination) {
      next()
    } else {
      next(Routes.SEARCH)
    }
  },
  mounted() {
    this.doSearch()
  },
  methods: {
    doSearch: _.debounce(async function() {
      this.popup.open = false
      this.searching = true

      let distance = 2000
      let [longitude, latitude] = this.position

      if (this.map) {
        const bounds = this.map.map.getBounds()
        const nw = bounds.getNorthWest().toArray()
        const center = bounds.getCenter().toArray()

        longitude = center[0]
        latitude = center[1]
        distance = Math.round(
          atlas.math.getDistanceTo(nw, center, atlas.math.DistanceUnits.meters)
        )
      }

      this.$apiService
        .searchChargingPointsAsync({
          longitude,
          latitude,
          distancemeters: distance
        })
        .then(cps =>
          cps.map(cp => ApiService.MapSearchResultFromApi(cp, this.search))
        )
        .then(points => {
          this.points = points.map(p => {
            p.properties.availabilityStatus = p.properties.private
              ? 'Private-'
              : 'Public-'

            if (p.isNotAvailableAtAll) {
              p.properties.availabilityStatus += 'Unavailable'
            } else if (p.startsTooLate || p.endsTooEarly) {
              p.properties.availabilityStatus += 'PartAvailable'
            } else {
              p.properties.availabilityStatus += 'Available'
            }

            return p
          })
        })
        .catch(err =>
          console.error(
            `Uncaught error while searching for charging points around [${
              this.position[0]
            }, ${this.position[1]}]`,
            err
          )
        )
        .finally(() => (this.searching = false))
    }, 500),
    formatTime(value) {
      const time = value == 1 ? this.search.startMoment : this.search.endMoment

      return time.format('HH[h]mm')
    },
    async onMapReady(e) {
      const loadSprites = Promise.all([
        e.map.imageSprite.add(
          'public-available',
          '../img/map/public-available.png'
        ),
        e.map.imageSprite.add(
          'public-part-available',
          '../img/map/public-part-available.png'
        ),
        e.map.imageSprite.add(
          'public-unavailable',
          '../img/map/public-unavailable.png'
        ),
        e.map.imageSprite.add(
          'private-available',
          '../img/map/private-available.png'
        ),
        e.map.imageSprite.add(
          'private-part-available',
          '../img/map/private-part-available.png'
        ),
        e.map.imageSprite.add(
          'private-unavailable',
          '../img/map/private-unavailable.png'
        )
      ])

      let spritesLoaded = false

      do {
        console.debug('Loading sprites...')
        await loadSprites
          .then(() => (spritesLoaded = true))
          .catch(async err => {
            console.error(
              '[SearchResult::onMapReady] an error occured while loading sprites: ',
              err
            )

            await new Promise(res => setTimeout(() => res(), 500))
          })
      } while (!spritesLoaded)

      this.map = e.map

      this.animate(0)
    },
    onPulseLayerCreated(layer) {
      this.pulseLayer = layer
    },
    onMapClick(e) {
      if (e.shapes && e.shapes.length > 0) {
        // Capture the selected shape.
        this.selectedShape = e.shapes[0]

        const props = this.selectedShape.getProperties()
        this.popup.coords = this.selectedShape.getCoordinates()

        e.map.setCamera({
          center: this.popup.coords,
          centerOffset: [0, -150],
          duration: 500,
          type: 'fly'
        })

        const [lon, lat] = this.position
        this.cp = new SearchResultCP(lon, lat, props, this.search)

        this.popup.open = true
        this.selectedShape = null
      }
    },
    onClusterClick(e) {
      if (
        e &&
        e.shapes &&
        e.shapes.length > 0 &&
        e.shapes[0].properties.cluster
      ) {
        //Get the clustered point from the event.
        var cluster = e.shapes[0]
        //Get the cluster expansion zoom level. This is the zoom level at which the cluster starts to break apart.
        this.$refs.dataSource.dataSource
          .getClusterExpansionZoom(cluster.properties.cluster_id)
          .then(zoom => {
            //Update the map camera to be centered over the cluster.
            this.map.setCamera({
              center: cluster.geometry.coordinates,
              zoom: zoom,
              type: 'ease',
              duration: 200
            })
          })
      }
    },
    animate(timestamp) {
      const duration = 3000
      const maxRadius = 50
      const progress = (timestamp % duration) / duration

      //Early in the animaiton, make the radius small but don't render it. The map transitions between radiis, which causes a flash when going from large radius to small radius. This resolves that.
      if (progress <= 0.7) {
        const subProgress = progress / 0.7

        this.pulseLayer.setOptions({
          radius: maxRadius * subProgress,
          opacity: 0.4 * (1 - subProgress)
        })
      } else {
        this.pulseLayer.setOptions({
          radius: 0,
          opacity: 0
        })
      }

      //Request the next frame of the animation.
      requestAnimationFrame(this.animate)
    },
    onZoomStart() {
      this.zoomStart = this.map.getCamera().zoom
    },
    onZoomEnd() {
      const zoomEnd = this.map.getCamera().zoom

      if (zoomEnd < this.zoomStart) {
        this.doSearch()
      }

      this.zoomStart = null
    }
  }
}
</script>
<style lang="scss">
@import '@/styles.scss';

.pulseIcon {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: orange;
  border: 2px solid white;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(0, 204, 255, 0.4);
  animation: pulse 3s infinite;
}

.pulseIcon:hover {
  animation: none;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 204, 255, 0.4);
  }

  70% {
    box-shadow: 0 0 0 50px rgba(0, 204, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 204, 255, 0);
  }
}

#search-results {
  background-color: #f8f8f8;

  .main-container {
    .address {
      border: none;
      border-radius: 10px;
      -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.22);
      -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.22);
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.22);
      height: 48px;
      background: #fff url('../assets/img/icon-search.png') no-repeat 15px;
      padding-left: 40px;
      font-weight: initial;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0;
      color: $dark;
    }

    .search-actions {
      font-size: 1.2rem;
      line-height: 3rem;

      svg {
        margin: 0 10px;
        cursor: pointer;
      }
    }

    .filter {
      background: #fff url('../assets/img/icon-filter.png') no-repeat 15px;
      padding-left: 40px;
      font-weight: $font-weight-bold;
      font-size: 16px;
      letter-spacing: 0;
      color: #dedede;

      &:disabled {
        -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.22);
        -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.22);
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.22);
        color: #dedede;
      }
    }

    .form-group {
      margin-top: 20px;

      .col-form-label {
        font-weight: $font-weight-regular;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0;
      }
    }

    .d-inline-block {
      width: 100%;

      .slider {
        margin-top: 15px;

        &.slider-horizontal {
          width: 100%;
        }

        .slider-track,
        .slider-track-low,
        .slider-selection,
        .slider-track-high {
          background-image: none;
          height: 7px;
        }

        .slider-selection {
          background-color: #dedede;
          box-shadow: none;
        }

        .slider-track-low,
        .slider-track-high {
          background-color: #fff;
        }

        .tooltip.in {
          opacity: 1;

          .tooltip-inner {
            padding: 0;
            padding-top: 15px;
            background-color: transparent;
            font-family: $font-family;
            font-weight: $font-weight-bold;
            font-size: 9px;
            line-height: 11px;
            letter-spacing: 0;
            color: $dark;
          }
        }

        .slider-handle {
          width: 8px;
          top: -2px;
          margin-left: -4px;
          background-color: transparent;
          background-image: none;

          &:before {
            content: '';
            display: block;
            border: 4px solid transparent;
            border-top: 7px solid black;
          }
        }

        &:not(.slider-disabled) {
          .slider-handle:active {
            background-color: $button-active;

            &:before {
              background: repeating-linear-gradient(
                to right,
                #fff,
                #fff 1px,
                transparent 1px,
                transparent 5px
              );
            }
          }
        }
      }
    }
  }

  .azure-map-container {
    position: relative;
    width: 100%;
    height: 80%;

    .azure-map {
      width: 100%;
      height: 100%;

      .atlas-control-container {
        .non-fixed.subcontrol-container {
          display: none;
        }
      }
    }

    &.searching::before {
      content: '';
      position: absolute;
      top: -2px;
      width: 100%;
      height: 2px;
      background: $dark;
      animation: spin 4s linear infinite;
    }
  }

  @keyframes spin {
    0% {
      transform: scaleX(0);
      transform-origin: left;
    }

    50% {
      transform: scaleX(1);
      transform-origin: left;
    }

    50.1% {
      transform-origin: right;
    }

    100% {
      transform: scaleX(0);
      transform-origin: right;
    }
  }
}
</style>
