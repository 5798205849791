var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fill", attrs: { id: "search-results" } },
    [
      _c("HeaderNavigation", {
        attrs: { title: "SELECTIONNER UN POINT DE RECHARGE" }
      }),
      _c(
        "b-container",
        { staticClass: "main-container" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form",
                    { attrs: { novalidate: "" } },
                    [
                      _c(
                        "b-form-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "10", md: "9" } },
                            [
                              _c("b-form-input", {
                                staticClass: "address",
                                attrs: { type: "text", readonly: "" },
                                model: {
                                  value: _vm.search.destination.address,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.search.destination,
                                      "address",
                                      $$v
                                    )
                                  },
                                  expression: "search.destination.address"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass: "text-right",
                              attrs: { cols: "4", md: "3" }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "search-actions" },
                                [
                                  _c("fa-icon", {
                                    attrs: { icon: "redo-alt" },
                                    on: {
                                      click: function($event) {
                                        return _vm.doSearch()
                                      }
                                    }
                                  }),
                                  _c("fa-icon", {
                                    attrs: { icon: "sliders-h" },
                                    on: {
                                      click: function($event) {
                                        return _vm.$router.back()
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              false
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "filter",
                                      attrs: { variant: "light", disabled: "" }
                                    },
                                    [_vm._v("FILTRER")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-form-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-form-group",
                                [
                                  _c(
                                    "label",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [
                                      _vm._v(" Quel temps de recharge "),
                                      _c("b", [
                                        _vm._v(_vm._s(_vm.formattedDuration))
                                      ])
                                    ]
                                  ),
                                  _c("b-form-slider", {
                                    attrs: {
                                      value: [1, 3],
                                      max: 4,
                                      handle: "square",
                                      tooltip: "always",
                                      "tooltip-split": true,
                                      formatter: _vm.formatTime,
                                      disabled: ""
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "azure-map-container",
          class: { searching: _vm.searching }
        },
        [
          _c(
            "AzureMap",
            {
              staticClass: "azure-map",
              attrs: { center: _vm.position, zoom: 15 },
              on: {
                ready: _vm.onMapReady,
                boxzoomend: function($event) {
                  return _vm.doSearch()
                },
                dragend: function($event) {
                  return _vm.doSearch()
                },
                zoomstart: _vm.onZoomStart,
                zoomend: _vm.onZoomEnd
              }
            },
            [
              _c("AzureMapZoomControl"),
              _c(
                "AzureMapDataSource",
                [
                  _c("AzureMapPoint", {
                    attrs: {
                      longitude: _vm.position[0],
                      latitude: _vm.position[1]
                    }
                  }),
                  _c("AzureMapBubbleLayer", {
                    attrs: { options: _vm.userLayerOptions.pulse },
                    on: { created: _vm.onPulseLayerCreated }
                  }),
                  _c("AzureMapBubbleLayer", {
                    attrs: { options: _vm.userLayerOptions.dot }
                  })
                ],
                1
              ),
              _c(
                "AzureMapDataSource",
                {
                  ref: "dataSource",
                  attrs: {
                    cluster: true,
                    "cluster-properties": _vm.clusterProperties
                  }
                },
                [
                  _vm._l(_vm.points, function(point) {
                    return _c("AzureMapPoint", {
                      key: point.properties.id,
                      attrs: {
                        longitude: point.longitude,
                        latitude: point.latitude,
                        properties: point.properties
                      }
                    })
                  }),
                  _c(
                    "AzureMapPopup",
                    {
                      staticClass: "popup",
                      class: {
                        available: _vm.cp && _vm.cp.isAvailableDuring,
                        "part-available":
                          _vm.cp &&
                          (_vm.cp.endsTooEarly || _vm.cp.startsTooLate),
                        unavailable: _vm.cp && _vm.cp.isNotAvailableAtAll
                      },
                      attrs: {
                        position: _vm.popup.coords,
                        "pixel-offset": [0, -43],
                        fillColor: _vm.popupFillColor
                      },
                      model: {
                        value: _vm.popup.open,
                        callback: function($$v) {
                          _vm.$set(_vm.popup, "open", $$v)
                        },
                        expression: "popup.open"
                      }
                    },
                    [
                      !!_vm.cp
                        ? _c("ChargingPointPopup", { attrs: { cp: _vm.cp } })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("AzureMapSymbolLayer", {
                    attrs: { options: _vm.symbolLayerOptions },
                    on: { click: _vm.onMapClick }
                  }),
                  _c("AzureMapBubbleLayer", {
                    attrs: { options: _vm.bubbleLayerOptions },
                    on: { click: _vm.onClusterClick }
                  }),
                  _c("AzureMapSymbolLayer", {
                    attrs: { options: _vm.textLayerOptions }
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }