import { Search, formatDuration } from '@/utils/search-utils.js'

const SearchMixin = {
  computed: {
    search() {
      const { destination, when, time, duration } = this.$store.state.search

      return new Search(destination, when, time, duration)
    }
  },
  methods: {
    formatDuration(duration) {
      return formatDuration(duration)
    }
  }
}

export default SearchMixin
