<template>
  <div class="popup-content">
    <h1>Point de recharge #{{ cp.properties.id }}</h1>
    <b-container>
      <b-row no-gutters>
        <b-col>
          <p>
            Quel temps de recharge
            <strong>{{ durationString }}</strong>
          </p>
          <p>
            <b-form-slider
              :value="[1, 4]"
              :max="5"
              handle="square"
              tooltip="always"
              :tooltip-split="true"
              :formatter="formatTime"
              disabled
              :class="{
                before: cp.isAvailableBefore,
                during: cp.isAvailableDuring,
                after: cp.isAvailableAfter,
                late: cp.startsTooLate,
                early: cp.endsTooEarly
              }"
            />
          </p>
          <p>Recharge pour environ {{ estimatedDistance }} km</p>
          <p>{{ cp.properties.type }} - {{ cp.properties.power }} kVA</p>
        </b-col>
      </b-row>
      <b-row class="picture-row flex-nowrap" no-gutters>
        <b-col cols="4">
          <b-img-lazy
            v-for="(photo, idx) in photos"
            :key="idx"
            :src="photo.url"
            class="thumb"
          />
        </b-col>
      </b-row>
    </b-container>
    <div class="price" v-if="!cp.isNotAvailableAtAll">
      {{ isMyChargingPoint ? roundedPrice : chargedPrice }} €
    </div>
    <div class="price unavailableAtAll" v-else>Non disponible</div>
    <div class="disc"></div>
    <b-button
      v-if="isMyChargingPoint"
      :to="myCPRoute"
      class="book"
      variant="dark"
    >
      Détails
    </b-button>
    <b-button
      v-else-if="!cp.isNotAvailableAtAll"
      v-loader.disable="bookingInProgress"
      class="book"
      variant="dark"
      @click="bookChargingPoint"
    >
      RESERVER
    </b-button>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import SearchMixin from '@/mixins/search.js'
import { SearchResultCP } from '@/utils/search-utils'
import { Routes } from '@/router.js'

const moment = require('moment')
moment.locale('fr-FR')

export default {
  name: 'chargin-point-popup',
  props: {
    cp: { type: SearchResultCP, required: true }
  },
  data() {
    return {
      photos: [],
      bookingInProgress: false
    }
  },
  mixins: [SearchMixin],
  computed: {
    myCPRoute() {
      return {
        ...Routes.MY_CHARGING_POINT,
        params: { id: this.cp.properties.id }
      }
    },
    isMyChargingPoint() {
      return this.cp.properties.ownerId == this.$store.getters.userId
    },
    computedDuration() {
      return moment.duration(
        this.cp.computedEndMoment.diff(this.cp.computedStartMoment)
      )
    },
    duration() {
      return this.cp.isNotAvailableAtAll
        ? this.search.durationMoment
        : this.computedDuration
    },
    // Distance = Time(Hours) * Power(KVA) * 6.08
    estimatedDistance() {
      const power = Number(this.cp.properties.power)

      return Math.round(this.duration.asHours() * power * 6.08)
    },
    durationString() {
      return this.formatDuration(this.duration)
    },
    // Base price is for 30 minutes
    // Min price is 50 cts
    price() {
      return Math.max(
        0.5,
        this.cp.properties.price * (this.computedDuration.asMinutes() / 30)
      )
    },
    roundedPrice() {
      return Math.floor(100 * this.price) / 100
    },
    // We add 20% Evenir fees
    chargedPrice() {
      return Math.floor(120 * this.price) / 100
    }
  },
  mounted() {
    this.$apiService
      .getChargingPointPhotosAsync(this.cp.properties.id)
      .then(photos =>
        Promise.all(
          photos.map(p =>
            this.$apiService
              .getChargingPointPhotoContentDataURLAsync(p.resourceId, p.fileId)
              .then(url => {
                p.url = url

                return p
              })
          )
        )
      )
      .then(photos => (this.photos = photos || []))
  },
  methods: {
    ...mapActions(['createBooking']),
    formatTime(value) {
      return (value == 1
        ? this.cp.computedStartMoment
        : this.cp.computedEndMoment
      ).format('HH[h]mm')
    },
    async bookChargingPoint() {
      try {
        this.bookingInProgress = true
        const paymentIntent = await this.$apiService.getPaymentIntentAsync(
          this.computedDuration.asMinutes(),
          this.cp.properties.id
        )

        if (paymentIntent) {
          // TODO: See how to use instance import
          const instance = window.Stripe(
            process.env.VUE_APP_STRIPE_API_PublicKey
          )
          const result = await instance.handleCardPayment(
            paymentIntent.clientSecret,
            {
              payment_method: paymentIntent.paymentMethodId
            }
          )

          if (result.error) {
            throw new Error(
              'Error while handling card payment: ' + result.error
            )
          }

          // TOTO: Check result.status == "succeeded" ?
          await this.createBooking({
            api: this.$apiService,
            booking: {
              cp: this.cp,
              time: {
                start: this.cp.computedStartMoment.toDate(),
                end: this.cp.computedEndMoment.toDate()
              },
              price: paymentIntent.chargedPrice,
              basePrice: paymentIntent.basePrice,
              estimatedDistance: this.estimatedDistance,
              stripePaymentIntentId: paymentIntent.id
            }
          }).then(() => this.$router.push(Routes.MY_BOOKINGS))
        } else {
          throw new Error("Couldn't get payment intent.")
        }
      } catch (err) {
        console.error('Error while booking charging point: ', err)
      } finally {
        this.bookingInProgress = false
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/styles.scss';

.popup-content-container {
  border-radius: 0 0 15px 15px;
}

.popup-close {
  top: 20px;
  left: 20px;
}
</style>

<style lang="scss" scoped>
@import '@/styles.scss';

.popup-content {
  width: 375px;
  border-radius: 0 0 15px 15px;
  background-color: #f8f8f8;
  font-family: $font-family;
  color: $dark;

  h1 {
    padding: 20px 20px 0;
    font-weight: $font-weight-bold;
    font-size: 16px;
    letter-spacing: 0;
    text-align: right;
  }

  .container {
    padding-right: 20px;
    padding-left: 20px;

    p {
      font-size: 14px;
      margin-bottom: 0;
      padding: 5px 0;

      .d-inline-block {
        width: 100%;

        /deep/ .slider {
          margin-top: 15px;

          &.slider-horizontal {
            width: 100%;
          }

          .slider-track,
          .slider-track-low,
          .slider-selection,
          .slider-track-high {
            height: 7px;
            box-shadow: none;
            background-image: none;
            background-color: $unavailable;
          }

          .slider-track-low {
            border-radius: 4px 0 0 4px;
          }

          .slider-selection {
            border-radius: 0px;
          }

          .slider-track-high {
            border-radius: 0 4px 4px 0;
          }

          .tooltip.in {
            opacity: 1;
            /*margin-left: 4px;*/

            .tooltip-inner {
              padding: 0;
              padding-top: 15px;
              background-color: transparent;
              font-family: $font-family;
              font-weight: $font-weight-bold;
              font-size: 9px;
              line-height: 11px;
              letter-spacing: 0;
              color: $dark;
            }
          }

          .slider-handle {
            width: 8px;
            top: -2px;
            margin-left: -4px;
            background-color: transparent;
            background-image: none;

            &:before {
              content: '';
              display: block;
              width: 0;
              border: 4px solid transparent;
              border-top: 7px solid black;
            }
          }

          &:not(.slider-disabled) {
            .slider-handle:active {
              background-color: $button-active;

              &:before {
                background: repeating-linear-gradient(
                  to right,
                  #fff,
                  #fff 1px,
                  transparent 1px,
                  transparent 5px
                );
              }
            }
          }
        }

        &.before {
          /deep/ .slider {
            .slider-track-low {
              background: $available;
            }
          }
        }

        &.during {
          /deep/ .slider {
            .slider-selection {
              background: $available;
            }
          }
        }

        &.after {
          /deep/ .slider {
            .slider-track-high {
              background: $available;
            }
          }
        }

        &.late {
          /deep/ .slider {
            .slider-selection {
              background: $part-available;
            }

            .tooltip-min {
              .tooltip-inner {
                color: $invalid-data-color;
              }
            }
          }
        }

        &.early {
          /deep/ .slider {
            .slider-selection {
              background: $part-available;
            }

            .tooltip-max {
              .tooltip-inner {
                color: $invalid-data-color;
              }
            }
          }
        }
      }
    }
  }

  .picture-row {
    overflow-x: scroll;

    img.thumb {
      width: 88px;
      height: 92px;
      object-fit: cover;
    }
  }

  .price {
    color: #ffffff;
    font-size: 20px;
    font-weight: $font-weight-bold;
    text-align: center;
    margin-top: 10px;
    padding: 15px 0;
    border-radius: 0 0 15px 15px;
  }

  .disc {
    position: absolute;
    bottom: -45px;
    left: 50%;
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    margin-left: -25px;
    border: 8px solid white;
    border-radius: 50%;
    padding: 0;
    z-index: 1;
  }

  .available & {
    .price,
    .disc {
      background: $available;
    }
  }

  .part-available & {
    .price,
    .disc {
      background: $part-available;
    }
  }

  .unavailable & {
    .price,
    .disc {
      background: $unavailable;
    }
  }

  .book {
    position: absolute;
    bottom: -98px;
    left: 50%;
    width: 98px;
    margin-left: -48px;

    &:before {
      display: block;
      position: absolute;
      top: -10px;
      left: 39px;
      margin: 0;
      padding: 0;
      width: 0;
      height: 10px;
      border-bottom: solid 10px $dark;
      border-left: solid 10px transparent;
      border-right: solid 10px transparent;
      content: '';
    }

    &:hover {
      &:before {
        border-bottom-color: #19e6d0;
      }
    }

    &:active {
      &:before {
        border-bottom-color: #47fce8;
      }
    }
  }
}
</style>
