var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup-content" },
    [
      _c("h1", [_vm._v("Point de recharge #" + _vm._s(_vm.cp.properties.id))]),
      _c(
        "b-container",
        [
          _c(
            "b-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("b-col", [
                _c("p", [
                  _vm._v(" Quel temps de recharge "),
                  _c("strong", [_vm._v(_vm._s(_vm.durationString))])
                ]),
                _c(
                  "p",
                  [
                    _c("b-form-slider", {
                      class: {
                        before: _vm.cp.isAvailableBefore,
                        during: _vm.cp.isAvailableDuring,
                        after: _vm.cp.isAvailableAfter,
                        late: _vm.cp.startsTooLate,
                        early: _vm.cp.endsTooEarly
                      },
                      attrs: {
                        value: [1, 4],
                        max: 5,
                        handle: "square",
                        tooltip: "always",
                        "tooltip-split": true,
                        formatter: _vm.formatTime,
                        disabled: ""
                      }
                    })
                  ],
                  1
                ),
                _c("p", [
                  _vm._v(
                    "Recharge pour environ " +
                      _vm._s(_vm.estimatedDistance) +
                      " km"
                  )
                ]),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.cp.properties.type) +
                      " - " +
                      _vm._s(_vm.cp.properties.power) +
                      " kVA"
                  )
                ])
              ])
            ],
            1
          ),
          _c(
            "b-row",
            {
              staticClass: "picture-row flex-nowrap",
              attrs: { "no-gutters": "" }
            },
            [
              _c(
                "b-col",
                { attrs: { cols: "4" } },
                _vm._l(_vm.photos, function(photo, idx) {
                  return _c("b-img-lazy", {
                    key: idx,
                    staticClass: "thumb",
                    attrs: { src: photo.url }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      !_vm.cp.isNotAvailableAtAll
        ? _c("div", { staticClass: "price" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.isMyChargingPoint ? _vm.roundedPrice : _vm.chargedPrice
                ) +
                " € "
            )
          ])
        : _c("div", { staticClass: "price unavailableAtAll" }, [
            _vm._v("Non disponible")
          ]),
      _c("div", { staticClass: "disc" }),
      _vm.isMyChargingPoint
        ? _c(
            "b-button",
            {
              staticClass: "book",
              attrs: { to: _vm.myCPRoute, variant: "dark" }
            },
            [_vm._v(" Détails ")]
          )
        : !_vm.cp.isNotAvailableAtAll
        ? _c(
            "b-button",
            {
              directives: [
                {
                  name: "loader",
                  rawName: "v-loader.disable",
                  value: _vm.bookingInProgress,
                  expression: "bookingInProgress",
                  modifiers: { disable: true }
                }
              ],
              staticClass: "book",
              attrs: { variant: "dark" },
              on: { click: _vm.bookChargingPoint }
            },
            [_vm._v(" RESERVER ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }